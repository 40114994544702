:root {
  --textColor: #C6D0F5;
  --bgColor: #232634d0;
  --bgColorTr: #232634f0;
  --bgSecondaryColor: #292C3C;
  --bgTertiaryColor: #303446;
  --boxColor: #89b4fa;
  --successColor: #a6e3a1;
  --dangerColor: #f38ba8;
  --accentColor: #ef9f76;
  background-color: var(--bgTertiaryColor);
  color: var(--textColor);
}

@font-face {
  font-family: billiondreams;
  src: url("./assets/fonts/billiondreams.ttf");
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Varela Round', 'Dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--textColor);
  opacity: 0.2; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--textColor);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--textColor);
}

input, textarea, select {
  font-family: 'Dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@keyframes slideFromTop {
  0% {
      opacity: 0;
      transform: scale(0.9) translateY(-50px);
  }
  50%{
    opacity: 1;
    transform: scale(0.9) translateY(0px);
  }
  100% {
      opacity: 1;
      transform: scale(1) translateY(0px);
  }
}

@keyframes slideToTop {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
  50%{
    opacity: 1;
    transform: scale(0.9) translateY(0px);
  }
  100% {
    opacity: 0;
    transform: scale(0.9) translateY(-50px);
  }
}

div, img {
  opacity: 1;
  transition: opacity 250ms ease;
}

div.loadingImage, img.loading {
  opacity: 0;
}