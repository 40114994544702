.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: fixed;
    font-size: x-large;
    background-image: linear-gradient(var(--bgColor), transparent);
    width: 100vw;
    box-shadow:
        0px 2.8px 2.2px rgba(0, 0, 0, 0),
        0px 6.7px 5.3px rgba(0, 0, 0, 0),
        0px 12.5px 10px rgba(0, 0, 0, 0),
        0px 22.3px 17.9px rgba(0, 0, 0, 0),
        0px 41.8px 33.4px rgba(0, 0, 0, 0),
        0px 100px 80px rgba(0, 0, 0, 0)
    ;
    transition: all 0.25s ease;
    z-index: 99;
    flex-wrap: nowrap;
}

.sidebarButton {
    flex-wrap: nowrap;
    flex-shrink: 0;
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 26px;
    margin-left: 12px;
    visibility: hidden;
    pointer-events: none;
}

.placeholder {
    height: 26px;
    width: 40px;
    margin-right: 12px;
}

.sidebarButton .line {
    flex-shrink: 0;
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--textColor);
}
  
.sidebarButton .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}
  
.sidebarButton .line2 {
    transition: transform 0.2s ease-in-out;
}
  
.sidebarButton .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.sidebarButton.active .line1 {
    transform: rotate(45deg) translateX(1px);
}
  
.sidebarButton.active .line2 {
    transform: scaleY(0);
}
  
.sidebarButton.active .line3 {
    transform: rotate(-45deg) translateX(1px);
}

@media screen and (max-width: 70rem) {
    .sidebarButton, .placeholder {
      visibility: visible;
      pointer-events: all;
    }

    .logo {
        font-size: 4rem;
    }
}