.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
}

.loading:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    backdrop-filter: blur(20px); 
    border-radius: 2rem;
}