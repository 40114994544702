.page .headerImage img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    border-bottom: 10px solid var(--bgColor);
}

.page .userHeader {
    display: flex;
    padding: 2rem;
}

.page .userHeader .userPfp img {
    width: 200px;
    height: 200px;
    border-radius: 2rem;
    border: 10px solid var(--bgColor);
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
}

.page .userHeader .userPfp {
    margin-top: -7rem;
}

.page .userHeader .userInfo {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.page .userHeader .userInfo .username {
    font-size: 2rem;
    font-weight: bolder;
}

.page .userHeader .userInfo .userDetails {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.page .userHeader .userInfo .userDetails .userDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 80px;
    background-color: var(--bgSecondaryColor);
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    border: 1px solid var(--bgColor);
}

.page .userHeader .userInfo .userDetails .userDetail .title {
    font-weight: bolder;
}

.home .userSettings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: top;
    animation: 500ms ease 0s 1 slideFromTop;
}

.home .userSettings form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.setting {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-bottom: 2rem;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 1;
}

.setting .settingTitle, .setting .settingInfo, .setting .inputContainer {
    width: 80%;
}

.setting .dropZone {
    background-color: var(--accentColor);
    padding: 1rem;
    width: 75%;
    border-radius: 2rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--bgColor)
}

.setting .dropZone.big {
    height: 100px;
}

.setting .dropZone .imageContainer {
    margin-top: 1rem;
}

.setting .dropZone .imageContainer img {
    width: 200px;
    height: 200px;
    border-radius: 2rem;
    border: 10px solid var(--bgColor);
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
}

.setting .dropZone .row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.setting .dropZone:hover {
    cursor: pointer;
}

.setting .dropZone svg {
    font-size: 3rem;
    margin-right: 1rem;
}

.setting .file {
    padding: 1rem 0;
}

.categories .categoryGrid {
    display: grid;
    padding: 2rem;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    transform-origin: top;
    animation: 500ms ease 0s 1 slideFromTop;
}

.categories .categoryGrid .category {
    position: relative;
    cursor: pointer;
}

.categories .categoryGrid .category span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bgColorTr);
    backdrop-filter: blur(20px);
    padding: 1rem;
    border-radius: 1rem;
    text-align: center;
}

.categories .categoryGrid .category img {
    object-fit: cover;
    width: 330px;
    height: 180px;
    border-radius: 1rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    text-shadow: 0px 15px 20px -8px var(--bgColor);
    transition: filter 250ms ease;
}

.categories .categoryGrid .category:hover img {
    filter: brightness(0.2);
}

.wallpapers .wallpaperGrid {
    display: grid;
    padding: 2rem;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    transform-origin: top;
    animation: 500ms ease 0s 1 slideFromTop;
}

.userGrid {
    display: grid;
    padding: 2rem;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    transform-origin: top;
    animation: 500ms ease 0s 1 slideFromTop;
    margin-left: -2.75rem;
}

.userGrid .pageButton.user {
    padding: 2rem;
    position: relative;
}

.userGrid .pageButton.user .userData {
    background-color: var(--bgColorTr);
    padding: 1rem;
    border-radius: 2rem;
}

.userGrid .user .userData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.userGrid .user .userData img {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    border-radius: 10rem;
}

.wallpapers .wallpaperGrid .wallpaper {
    position: relative;
}

.wallpapers .wallpaperGrid .wallpaper .dataInfo {
    position: absolute;
    top: 0rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: linear-gradient(to left top, transparent, var(--bgColor));
    border-radius: 1rem;
}

.wallpapers .wallpaperGrid .wallpaper .dataInfo .data {
    display: flex;
    align-items: center;
    padding: 0.2rem;
}

.wallpapers .wallpaperGrid .wallpaper .dataInfo .data .info {
    margin-left: 0.5rem;
}

.wallpapers .wallpaperGrid .wallpaper span {
    opacity: 0;
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    padding: 1rem;
    background: linear-gradient(transparent, var(--bgColor));
    width: 150px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: 250ms opacity ease;
}

.wallpapers .wallpaperGrid .wallpaper:hover {
    cursor: pointer;
}

.wallpapers .wallpaperGrid .wallpaper:hover span {
    opacity: 1;
}

.wallpapers .wallpaperGrid .wallpaper img {
    object-fit: cover;
    width: 180px;
    height: 330px;
    border-radius: 1rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    text-shadow: 0px 15px 20px -8px var(--bgColor);
}

.inputContainer .deleteIcon {
    background-color: var(--textColor);
    color: var(--bgTertiaryColor);
    border-radius: 100rem;
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload .setting, .admin .setting {
    transform-origin: top;
    animation: 500ms ease 0s 1 slideFromTop;
}

.setting .pageButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background-color: var(--bgColor);
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    border: 1px solid var(--bgColor);
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
}

@media screen and (max-width: 50rem) {
    .wallpapers .wallpaperGrid {
        grid-template-columns: auto auto auto;
    }

    .categories .categoryGrid .category img {
        width: 200px;
        height: 100px;
    }

    .userGrid {
        gap: 0.5rem;
    }

    .userGrid .pageButton.user {
        padding: 1rem;
        margin-left: 1.75rem;
    }
}

@media screen and (max-width: 45rem) {
    .page .userHeader .userInfo {
        margin-left: 0;
        text-align: center;
    }

    .page .userHeader {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    .page .userHeader .userInfo {
        align-items: center;
        margin-top: 1rem;
    }

    .page .userHeader .userInfo .userDetails {
        width: 100%;
    }

    .page .userHeader .userInfo .userDetails .userDetail {
        padding: 0.5rem;
        width: 120px;
    }
}

@media screen and (max-width: 40rem) {
    .wallpapers .wallpaperGrid {
        grid-template-columns: auto auto;
    }

    .userGrid {
        gap: 0.2rem;
    }

    .userGrid .pageButton.user {
        padding: 0.5rem;
        margin-left: 2.25rem;
    }
}

@media screen and (max-width: 25rem){
    .categories .categoryGrid .category img {
        width: 150px;
        height: 50px;
    }

    .page .userHeader .userInfo .userDetails .userDetail {
        padding: 0.25rem;
    }

    .wallpapers .wallpaperGrid .wallpaper img {
        width: 150px;
        height: 300px;
    }
}