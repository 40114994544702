.sidebarItem {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    transition: all 250ms ease;
    border: 1px solid transparent;
}

.sidebarItem:hover {
    background-color: var(--bgSecondaryColor);
    border: 1px solid var(--bgColor);
    cursor: pointer;
}

.sidebarItem.active {
    background-color: var(--accentColor);
    border: 1px solid var(--bgColor);
    color: var(--bgColor)
}

.sidebarItem.active {
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
}

.sidebarItem .icon svg {
    width: 20px;
    height: 20px;
    border-radius: 99rem;
    border: 1px solid var(--textColor);
    padding: 0.75rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
}

.sidebarItem.active .icon svg {
    border-color: var(--bgColor);
}

.sidebarItem .name {
    margin-left: 1rem;
}