.toast {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 1rem;
    z-index: 101;
    background-color: var(--accentColor);
    padding: 1rem;
    border: 1px solid var(--bgColor); 
    border-radius: 1rem;
    box-shadow: 0px 15px 20px -8px var(--bgColorTr);
    transition: all 250ms ease;
    transform: translateX(5rem);
    opacity: 0;
    color: var(--bgColor);
}

.toast.success, .toast.error {
    transform: translateX(0);
    opacity: 1;
}

.toast.success {
    background-color: var(--successColor);
}

.toast.error {
    background-color: var(--dangerColor);
}